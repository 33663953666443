import * as React from "react"
import Layout from "../components/Layout";

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Home</title>
      <Layout />
    </main>
  )
}

export default IndexPage
