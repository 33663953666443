import React from "react"
import PropTypes from "prop-types"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { useCommonData } from "../../data/useCommonData"
import Header from "../Header"
import DocumentsNav from "../DocumentsNav"

// import 'bootstrap/dist/css/bootstrap.min.css'
// import "../../styles/styles.scss"


const Layout = ({ children, sidebar }) => {

  const { site } = useCommonData()

  // console.log(navData)

  return (
      <>
        <Header siteTitle={site.siteMetadata?.title || `Title`}
                siteDescription={site.siteMetadata?.description}
        />
        <nav>
          <Container fluid>
            <Row>
              { sidebar && (
                  <>
                    <Col xl={2} md={3} sm={12}>
                      <DocumentsNav />
                    </Col>
                    <Col xl={10} md={9} sm={12}>
                      {children}
                    </Col>
                  </>
              )}
              { !sidebar && ( <Col>{children}</Col> )}

            </Row>
          </Container>
        </nav>
        <footer className="main-footer">
          Copyright © {new Date().getFullYear()} Practice Alpha
        </footer>
      </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
